
import $ from 'jquery';
export function fix_page_contents_class_height(is_projects){


    try {

        if(!is_projects){
                window.show_react_copy_popup = null;
                window.show_react_change_status_popup = null;

        }
    }
    catch(err){

    }





    var cb = function (){



        console.log("fixers");
        $('.pagecontents').css('min-height',$(window).height()+"px");

    };

    cb();
    $(window).on("resize",function(){

        cb();

    });
}
export function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
export function get_api_url_suffix(){
    return "inapp=1&session_key="+getCookie("token");
}
export function eraseCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
export function on_before_load_redirect_inside(navigate){
    var token = getCookie("token");
    console.log("try_test");
    if(window.location.pathname=='/login' || window.location.pathname=='/login/')
        try {
            if(token){


                window.location.href='/projects';
                //
                // return;
            } else {


            }
        }
        catch(err){

        }

    if(window.location.pathname!=='/login' && window.location.pathname!=='/login/')
    try {
        if(token){


            //
            // return;
        } else {

            window.location.href='/login';

        }
    }
    catch(err){

    }

}
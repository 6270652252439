//App.js
import { Route, Routes } from 'react-router-dom';
import './App.css';
import './Responsive.css';
import { useState,useEffect } from 'react';
import Home from './Home';
import Login from './Login';
import Register from './Register';
import Projects from './Projects';
import { useNavigate } from 'react-router-dom';
import './Scripts.js';
import * as scripts from "./Scripts";
import CreateProject from "./CreateProject";
import EditProject from "./EditProject";
import CustomSetup from "./CustomSetup";
import Confirmation from "./Confirmation";
import Reporting from "./Reporting";
import Forgotpassword from "./Forgotpassword";
import Profile from "./Profile";
import Wallet from "./Wallet";

window.api_url = 'https://app.quizxt.com/api/';
function App() {
    const navigate = useNavigate();


    useEffect(() => {




        if(window.location.pathname=='/')
            navigate('Login');

        //Runs only on the first render
    }, []);

    return (
        <div className="App">
            <Routes>
                <Route path='/login' element={<Login/>} />
                <Route path='/forgotpassword' element={<Forgotpassword/>} />
                <Route path='/register' element={<Register/>} />
                <Route path='/projects' element={<Projects/>} />
                <Route path='/createproject' element={<CreateProject/>} />
                <Route path='/editsetup/:project_id' element={<CreateProject/>} />
                <Route path='/editproject/:project_id' element={<EditProject/>} />
                <Route path='/customsetup/:project_id' element={<CustomSetup/>} />
                <Route path='/confirmation/:project_id' element={<Confirmation/>} />
                <Route path='/reporting' element={<Reporting/>} />
                <Route path='/profile' element={<Profile/>} />
                <Route path='/wallet' element={<Wallet/>} />
                <Route path='/reporting/:project_id' element={<Reporting/>} />
            </Routes>
        </div>
    );
}

export default App;
//Home.js
import React, {useEffect, useState} from 'react'
import Navbar from './Navbar'
import {Link, useNavigate} from "react-router-dom";
import './App.css';
import './Scripts.js';
import * as scripts from "./Scripts";
import Logo from './components/Logo'
import $ from "jquery";
import PurpleLoader from './components/PurpleLoader';


const Register = () => {
    const navigate = useNavigate();
    const [is_submitting, SetSubmitting] = useState(false);

    const [errors_array, SetErrorsArray] = useState([]);



    useEffect(() => {
        scripts.fix_page_contents_class_height();

    }, [])

    const handleChange =  (event) => {


    }
    const handleSubmit = (event) => {
        event.preventDefault();


        console.log(event);
        const data = new FormData(event.target);


        var email = data.get('email');
        var password = data.get('password');
        var company = data.get('company');
        var fullname = data.get('fullname');
        var currency = data.get('currency');
        var passwordconfirm = data.get('passwordconfirm');
        var agree_to = data.get('agree_to');


        var errors_arr = [];
        var errors_str = '';
        if(email==''){

            errors_arr.push("Please enter email");
        }
        else {

        }


        if(company==''){

            errors_arr.push("Please enter company name");
        }
        else {

        }



        if(password==''){
            errors_arr.push("Please enter password");
        }
        else
            if(password.length<8){

                errors_arr.push("Password must have at least 8 characters");
            }


            if (!password.match(/[A-Z]+/)){
                errors_arr.push("Password must have a capital letter");

            }
            if (!password.match(/[$@#&!]+/)

            ){
                    errors_arr.push("Password should have a special symbol");
            }




        if(password!==passwordconfirm){
            errors_arr.push("Password and password confirm are not equal.");
        }


        if(!agree_to){
            errors_arr.push("You must agree to terms and conditions.");
        }

        console.log(window.api_url);
        if(errors_arr.length>0){
            SetErrorsArray(errors_arr);
            return;
        }

        SetSubmitting(true);

        $.post(window.api_url+"members/create?inapp=1",{
            "email":email,
                "username": email,
            "fullname":fullname,
            "client":company,
            "user_selected_currency":currency,
                "password": password,
                "multicur":"1"},
            function(data){
                SetSubmitting(false);
                console.log(data);

                if(data.sk){

                    scripts.setCookie("token",data.sk);
                    navigate('/Projects');


                }

            },'json').fail(function(error){
            SetSubmitting(false);



            $('#errors_div').html(error.responseJSON.error);

        });






    }


    return (
        <div>

            <div className="allpagewrapper">
                <div className="leftbar">
                    <Logo hide_navigation="1" />

                </div>

                <div className="pagecontents">
                    <h1>Register</h1>
                    <div className="form">



                        <form action="" onSubmit={handleSubmit}>



                        <div className="label_wrapper">
                            <label htmlFor="username">*Email</label>
                            <div>
                                <input  onChange={handleChange} type="email" name="email" />
                            </div>

                        </div>


                            <div className="label_wrapper">
                                <label htmlFor="username">Name</label>
                                <div>
                                    <input  onChange={handleChange} type="text" name="fullname" />
                                </div>

                            </div>

                            <div className="label_wrapper">
                                <label htmlFor="company">*Company Name</label>
                                <div>
                                    <input  onChange={handleChange} type="text" name="company" id="company" />
                                </div>

                            </div>

                            <div className="label_wrapper">
                                <label htmlFor="company">*Invoice Currency</label>
                                <div>
                                    <select onChange={handleChange} name="currency" id="currency">
                                        <option value="18">USD</option>
                                        <option value="21">GBP</option>
                                        <option value="2">EUR</option>
                                    </select>
                                </div>

                            </div>

                        <div className="label_wrapper">
                            <label htmlFor="username">*Password <span className="explain">(Must have at least 8 characters, a Capital and a symbol or number)</span></label>
                            <div>
                                <input maxLength="23"  onChange={handleChange} type="password" name="password" />
                            </div>

                        </div>

                            <div className="label_wrapper">
                                <label htmlFor="passwordconfirm">*Password Confirm</label>
                                <div>
                                    <input id="passwordconfirm"  onChange={handleChange} type="password" name="passwordconfirm" />
                                </div>

                            </div>






                            <div className="agree_to">
                                <input type="checkbox" name="agree_to" id="agree_to" />&nbsp;
                                <label className="agree_to" htmlFor="agree_to">
                                I agree to Ad Test Media <a target="_blank" href="https://www.adtest.ai/terms-2/">Terms and Conditions </a>and <a target="_blank" href="https://www.adtest.media/privacy-policy/">Privacy Policy</a>. I also understand that the information I submit through this forms will be securely stored and used to keep me informed about products, and news.
                                </label>

                            </div>


                            <div className="clear"></div>


                            <div className="errors nomarg" id="errors_div">

                                {errors_array.map((item,index) => (


                                    <div>{item}</div>


                                ))
                                }


                            </div>

                        <div className="submitor regsubmit">
                            {is_submitting ?
                                <PurpleLoader height={40} /> : ''}

                            <input disabled={is_submitting} type="submit" value="Create My Account" />
                        </div>

                            <div className="clear"></div>


                        <div className="already_registered">
                            <span className="a_top">Already Registered?</span>
                            <span className="a_bottom">&nbsp;
                                <Link to="/login">Login</Link>
        </span>

                        </div>


                            <div className="clear"></div>

                            <div id="more_spacing"></div>

                        </form>
                    </div>
                </div>
            </div>
<div className="clear"></div>
<div className="clear"></div>
        </div>

    )
}

export default Register
import React from 'react'

class Logo extends React.Component{

    render() {

        if(this.props.product_price==0)
            return '';
        return (
            <div className={"estimated_cost"+(this.props.display=="true" ? " show": "")}>
                <div className="estimated_in">
                                                <span className="line_one">
                                                    Estimated Cost*
                                                </span>
                    <span className="line_two">{this.props.product_price}</span>
                </div>
            </div>
        )
    }

}

export default Logo;
import React from 'react'

class ConnectionError extends React.Component{

    render() {
        return (
            <div className="connection_error">
                internet connection is off.
            </div>
        )
    }

}

export default ConnectionError;
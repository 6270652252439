//Home.js
import React, {useEffect, useMemo, useState} from 'react'
import { useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
import $ from 'jquery';
import Navbar from './Navbar'
import {Link, useNavigate, useParams} from "react-router-dom";
import './App.css';
import './Scripts.js';
import * as scripts from "./Scripts";
import Logo from './components/Logo'
import Login from "./Login";
import Select from 'react-select'
import Calculator from "./components/Calculator";
import styled from 'styled-components'
import {GenderAmbiguous} from '@styled-icons/bootstrap/GenderAmbiguous'
import {CalendarLtr} from '@styled-icons/fluentui-system-regular/CalendarLtr'
import {Location} from '@styled-icons/fluentui-system-regular/Location'
import {TargetArrow} from '@styled-icons/fluentui-system-regular/TargetArrow'
import {PersonHeart} from '@styled-icons/bootstrap/PersonHeart';
import {BriefcaseFill} from '@styled-icons/bootstrap/BriefcaseFill';
import {PeopleFill} from '@styled-icons/bootstrap/PeopleFill';
import {Baby} from '@styled-icons/fa-solid/Baby';
import {PeopleAudience} from '@styled-icons/fluentui-system-filled/PeopleAudience';
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import PurpleLoader from './components/PurpleLoader';
import ConnectionError from "./components/ConnectionError";

const ico_height = '25px';

const Genderamb = styled(GenderAmbiguous)`
      height:${ico_height}
    `;

const CalendarIco = styled(CalendarLtr)`
height:${ico_height}
      
    `;

const LocationIco = styled(Location)`
height:${ico_height}
      
    `;
const TargetIco = styled(TargetArrow)`
height:${ico_height}
      
    `;

const PersonIco = styled(PersonHeart)`
height:20px
      
    `;


const WorkIco = styled(BriefcaseFill)`
height:16px
      
    `;

const PeopleIco = styled(PeopleFill)`
height:16px
      
    `;

const ChildIco = styled(Baby)`
height:19px
      
    `;

const PeopleAduienceIco = styled(PeopleAudience)`
height:19px
      
    `;

const EditProject = () => {







    const { project_id } = useParams();
    const navigate = useNavigate();
    scripts.on_before_load_redirect_inside(navigate);


    const [is_submitting, SetSubmitting] = useState(false);
    useEffect(() => {
        scripts.fix_page_contents_class_height();

    },[]);


    const { register,trigger,control, handleSubmit, formState: { errors } } = useForm();


    const onSubmit = function() {



        const data = $(".EditProjectfrm").find('select,input,textarea')
            .serialize();

        SetSubmitting(true);

        $.ajax({
            url: window.api_url+'adtest/main/adtest_save_survey_targeting'+"?sid="+project_id+"&sub_total="+sub_total+"&"+scripts.get_api_url_suffix(),
            type: 'post',
            data: data,
            contentType: false,
            processData: false,
            success: function(response){
                SetSubmitting(false);


                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);

                const confirm = urlParams.get('confirm')


                if(confirm){
                navigate('/confirmation/'+project_id);

                } else {
                navigate('/customsetup/'+project_id);

                }




                // fetchData();

                // var json = $.parseJSON(data);

            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {

                SetSubmitting(false);
                alert("internet_connnection_problem");


            }

        });


    }

    const get_empty_auto_populate_cache = function(){
        return {
            age:'',
            gender:'',
            location:''
        };
    }

    const [auto_populate_data, setAutoPopulateData] = useState(get_empty_auto_populate_cache());


    const [data, setData] = useState(0);

    const [adtest_amount_left_to_pay, setCalculatedPrice] = useState(0);
    const [location_type, setLocType] = useState('national');

    const [location_type_label, setLocTypeLabel] = useState('');


    const [location_quota_options, setLocQOpt] = useState([]);

    const [additional_targeting_options, setAdditionalTargetingOpts] = useState([]);

    const [additional_children_data, setAdditionalChildrenData] = useState([]);
    const [children_options, setChildOpts] = useState([]);




    const [survey_targeting_options, setSurveyTargetingOpts] = useState([]);






    const [age_quotas, setAgeQuotas] = useState([

    ]);


    useEffect(() => {

        if(location_type=="national"){
            console.log("gotwa");

            setLocationQuotas([


            ]);

            return;
        }
console.log("shange loc type");
var location_type_label = "";

location_quota_options.forEach(function(loc_opt, inner_index) {


    if(loc_opt.key==location_type){
        if(loc_opt.name=='DMA_NAME'){
            location_type_label = 'DMA';
        } else
        location_type_label = loc_opt.name;
    }
});

setLocTypeLabel(location_type_label);





        if(!dont_reset_location_quotas) {

            console.log("type is : "+location_type)

            if (location_type !== "national") {


                setLocationQuotas([

                    {
                        "location_type": location_type,
                        "location_val": "",
                        "id": create_new_id_from_type_and_index("location", 0)
                    }

                ]);

                //no locations remove location quotas


            } else {

console.log("nationa_empty");
                setLocationQuotas([]);


                //set one empty location instead
                //


            }
        }
        else {
            SetDontresetlocq(false);
        }

    },[location_type]);

        useEffect(() => {

            trigger('ages_validate');
            console.log(age_quotas);
    }, [age_quotas]);

    const [show_additional_targeting, setShowAdditional] = useState(false);



    const [gender_quotas, setGenderQuotas] = useState([

    ]);



    const [location_quotas, setLocationQuotas] = useState([

    ]);



    const [project_title, setProjecttitle] = useState('');
    const [sub_total, setProjectSubtotal] = useState(0);



    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // let {id} = useParams();

    useEffect(() => {

        fetchData();
    }, []);

    const [dont_reset_location_quotas, SetDontresetlocq] = useState(true);




    const fetchData = async () => {
        try {
            const response = await fetch(window.api_url+'adtest/main/adtest_get_project_step_one_data?project_id='+project_id+"&"+scripts.get_api_url_suffix()); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();



            if(data.amount_left_to_pay){
                setCalculatedPrice(data.amount_left_to_pay);
            }

            if(data.survey_ps_location_type || data.survey_ps_location_type===""){
                setLocType(data.survey_ps_location_type);
            } else {
                setLocType('regions');
            }


            if(data.additional_targeting_options){
                setAdditionalTargetingOpts(data.additional_targeting_options);
            }

            if(data.children){
                setAdditionalChildrenData(data.children);

                setChildOpts(Object.keys(data.children));

            }



            if(data.survey_targeting){
                setSurveyTargetingOpts(data.survey_targeting);
            }

            if(data.location_quota_options){
                setLocQOpt(data.location_quota_options);
            }


            if(data.quotas_age && data.quotas_age.length>0){




                setAgeQuotas(data.quotas_age);

            } else
            setAgeQuotas([        {"age_from":18,"age_to":99,id:create_new_id_from_type_and_index("age",0)}



            ]);



            if(data.quotas_gender && data.quotas_gender.length>0) {

                setGenderQuotas(data.quotas_gender);

            }
            else

                setGenderQuotas([
                {"gender":"m",id:create_new_id_from_type_and_index("gender",0)},
                {"gender":"f",id:create_new_id_from_type_and_index("gender",1)},
                {"gender":"nb",id:create_new_id_from_type_and_index("gender",2)}




            ]);


            if(data.quotas_location) {

                setLocationQuotas(data.quotas_location);

            }


            // setLocationQuotas([
            //
            //     {"location_type":"region",
            //     "location_val":"Northeast"
            //     }
            //
            //     ]);

            setProjecttitle(data.project_title);
            setProjectSubtotal(data.sub_total);

            setData(data);

            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    function set_location_type(event) {


SetDontresetlocq(false);
        setLocType(event.target.value);


        var to_loop=[...location_quotas];


        var old_location_vals = [];


        to_loop.forEach(function(value, this_index){



            old_location_vals.push(value.location_val);
            value.location_val = "";

        });







        $('.location_delete_hack').find("div").each(function(){
           var this_text = $(this).html();


           var was_found = false;


           old_location_vals.forEach(function(this_val,this_idx) {
               if(this_val==this_text){
                   was_found = true;
               }
            });


        if(was_found){
            $(this).text("");
        }
        });
        console.log(to_loop);
        setLocationQuotas(to_loop);


    }


    function fix_arr_ids(new_ar,type) {
        var very_new_ar = [...new_ar];
        var id_fix_new_ar = [];

        very_new_ar.forEach(function(value, this_index) {

            var new_id = ""+value.id;


            if(new_id.indexOf("newquota")!==-1){


                value.id = create_new_id_from_type_and_index(type,this_index);

            }

            id_fix_new_ar.push(value);

        });

        return id_fix_new_ar;
    }

    function delete_quota_row(type,index){
        var to_loop;

        if(type=='age'){
            to_loop = [...age_quotas];
        }
        else
            if(type=='gender'){
                to_loop = [...gender_quotas];
            }
            else
                if(type=='location'){
                    to_loop=[...location_quotas];
                }
            var new_ar = [];

            if(to_loop.length==1 && type!=='location'){
                alert("project must have at least one "+type+" row");
                return;

            }
        if(to_loop.length==1 && type=='location'){
            alert("Selection must have at least one row. If you require no rows, choose national");
            return;

        }


            to_loop.forEach(function(value, this_index){
                    console.log("checking index : "+this_index);

                if(index==this_index){

                } else {






                        // console.log(value);
                    new_ar.push(value);
                }
            });



         var id_fix_new_ar = fix_arr_ids(new_ar,type);


            if(type=='age'){
                setAgeQuotas(id_fix_new_ar);


            }
                else
                    if(type=='gender')
                        setGenderQuotas(id_fix_new_ar);
                else
                    if(type=='location')
                            setLocationQuotas(id_fix_new_ar);

        }


    function create_new_id_from_type_and_index(type,index){
        return type+"newquota"+index;
    }

    function find_gender_non_picked() {
        var all_options = ["nb","f","m"];



        var to_pick_opt = "m";
        all_options.forEach(function(opt, idx) {


            var was_found = false;
            gender_quotas.forEach(function(quota_item, inner_index) {


                console.log(quota_item);
                if(quota_item.gender==opt){
                    was_found = true;
                }



            });

            if(!was_found){


                to_pick_opt = opt;
            }


        });

        return to_pick_opt;

    }

    function add_quota_row(type,index){

        var to_loop,to_insert;
        var new_id = create_new_id_from_type_and_index(type,index);
        if(type=='age') {


            to_loop = [...age_quotas];

                to_insert = {age_from:13,age_to:99,id:new_id};

        }

        if(type=='gender') {


            to_loop = [...gender_quotas];


         var to_pick_opt = find_gender_non_picked();


                to_insert = {"gender":to_pick_opt,id:new_id};

        }

        if(type=='location') {

            to_loop = [...location_quotas];
            to_insert = {"location_type":"region",
                "location_val":"",id:new_id};

        }



            var new_arr = [];


        //this option only happens for location quotas where no quotas are added on the first time
        if(to_loop.length==0){
            new_arr.push(to_insert);
        }
        else

            to_loop.forEach(function(value, this_index){


                    new_arr.push(value);

                if(index===this_index){
                        new_arr.push(to_insert);
                }


            });


        var id_fix_new_ar = fix_arr_ids(new_arr,type);


        console.log(id_fix_new_ar);

        if(type=='age')
                setAgeQuotas(id_fix_new_ar);
            else
                if(type=='gender')
                    setGenderQuotas(id_fix_new_ar);
            else
                if(type=='location')
                        setLocationQuotas(id_fix_new_ar);


    }


    function set_qlimit_hidden_state(event){

        var this_id = (event.target.id);

        var new_value = (event.target.checked);

        var index = $('#'+this_id).attr("data-index");
        var type = $('#'+this_id).attr("data-type");




        // if(new_value)
        //     $('#'+type+'quotainp'+index).removeClass('hiddeninp');
        // else
        //     $('#'+type+'quotainp'+index).addClass('hiddeninp');

        var to_look_ar = get_to_loop_based_on_type(type);


        if(new_value) {

            console.log(to_look_ar[index]);

            if(!to_look_ar[index].qlimit){
                to_look_ar[index]['is_visible'] = 1;
                to_look_ar[index].qlimit = sub_total;
            }
        }
        else {
                delete to_look_ar[index].qlimit;
                try {
                    delete to_look_ar[index].is_visible;
                }
                catch(err){

                }
            }




        if(type=='age')
            setAgeQuotas(to_look_ar);
        else
        if(type=='gender')
            setGenderQuotas(to_look_ar);

        else
            if(type=='location')
                setLocationQuotas(to_look_ar);

    }

    function set_qlimit_value() {

    }

    function get_to_loop_based_on_type(type){

        var to_look_ar;

        if(type==='age'){

            to_look_ar = [...age_quotas];
        }
        if(type==='gender'){
            to_look_ar = [...gender_quotas];
        }
        if(type=='location'){
            to_look_ar = [...location_quotas];
        }

        return to_look_ar;
    }

    function handleLocselectchange(e,ev) {

    console.log(e);
    console.log(ev);

        var clonedData = [...location_quotas];


        var name = ev.name;


        var name_no_quotas = name.replace("quotas[","",name);

        var quota_id = name_no_quotas.substring(0,name_no_quotas.indexOf("]"));



        console.log(location_quotas);

        console.log(quota_id);


            var my_index;
        location_quotas.forEach(function(value, this_index) {

            if(value.id==quota_id){
                my_index = this_index;
            }

        });

        console.log(my_index);



        var index = parseInt(my_index);

console.log(clonedData[index]);
        if(e.option_val)
            clonedData[index]['location_val'] = (e.option_val);
        else {

            if(e.data_val)
                clonedData[index]['location_val'] = (e.data_val);

        }
        console.log(clonedData);

        setLocationQuotas(clonedData);



        console.log("trigger_validate");
        location_quotas.forEach(function(value, this_index) {

                trigger('location_val'+this_index);

        });




    }

    function handleAgeGenderChange(e) {



        var type = $('#'+e.target.id).attr("data-type");







        if(type=='age')
            var clonedData = [...age_quotas];
        else
            if(type=='gender')
                var clonedData = [...gender_quotas];

            else
            if(type=='location')
                var clonedData = [...location_quotas];

            var index = parseInt($('#'+e.target.id).attr("data-index"));


            var name = ($('#'+e.target.id).attr("data-name"));



            if(name=='qlimit'){
                clonedData[index]['is_visible']=1;
            }


            console.log("go_again : "+e.target.id);




            if(name=='age'){
                // trigger('ages_validate');
            }








            if(type=='age')
                clonedData[index][name] = parseInt(e.target.value);
            else
                if(type=='gender')
                    clonedData[index][name] = (e.target.value);

                else
                if(type=='location')
                    clonedData[index][name] = (e.target.value);





        console.log(clonedData);

            if(type=='age')
                setAgeQuotas(clonedData);
        else
            if(type=='gender')
                setGenderQuotas(clonedData);

            else
            if(type=='location')
                setLocationQuotas(clonedData);



        if(name=="location_val"){
            console.log("validate_locations");

            location_quotas.forEach(function(value, this_index) {

                trigger('location_val'+this_index);

            });

        }

        if(name=='gender') {

            gender_quotas.forEach(function(q_item, inner_index) {

                trigger('quota_gender'+inner_index);


            });
        }


    }

    function get_top_add_shared_btn(type,index){
        var extra_class = '';


        if(type=='gender'){
            if(gender_quotas.length==3){
                extra_class = 'greyed_out';
            }
        }
        var top_add =         <span className={"quota_delete_add_ico add_quota_row "+extra_class} onClick={()=>extra_class ? '' : add_quota_row(type,index)}></span>;

        return top_add;
    }
    function right_quota(item,type,index){
var top_delete =             <span className="quota_delete_add_ico delete_quota_row" onClick={()=>delete_quota_row(type,index)}></span> ;

        var top_add =  get_top_add_shared_btn(type,index);

        return         <div> {top_delete} {top_add}
            <div className="tick_label_wrapper">

                <input type="hidden" data-name="quota_order" data-type={type} data-index={index} name={"quotas["+item.id+"][quota_order]"} id={"quota_"+type+"_order_"+index} value={index} />


                <label htmlFor={type+"quotatick"+index} className="tick_label">Quotas</label>
                <span className="ticker">



    <input data-index={index} data-type={type} id={type+"quotatick"+index} onChange={set_qlimit_hidden_state} type="checkbox" checked={item.qlimit>0 ? true : false}/>


                </span>



                <input
                    max={sub_total}
                    {...register("quota_"+type+"_qlimit_"+index, {validate:function(value){if($('#'+type+'quotatick'+index).is(':checked') && (!item.qlimit || parseInt(item.qlimit)>sub_total || parseInt(item.qlimit)<1)) return 'please enter a valid quota';}})}

                    type="number" className={(((item.qlimit>0 && item.qlimit!==sub_total) || item.is_visible ) ? "" : "hiddeninp")+" qlimit"} data-name="qlimit" data-type={type} data-index={index} onChange={handleAgeGenderChange} name={"quotas["+item.id+"][qlimit]"} id={"quota_"+type+"_qlimit_"+index} value={item.qlimit || ''} />

                {errors["quota_"+type+"_qlimit_"+index] && <div
                    className="error_span">Please enter a valid quota</div>}


            </div>


        </div>;
    }


    function validate_location_val_at_index(index, location_val) {

        if(!location_val){
            return false;
        }



        var all_ok = true;

        location_quotas.forEach(function(value, this_index) {


            if(this_index!==index){



                if(value.location_val==location_val){
                    all_ok = false;
                }
            }

        });





        return all_ok;


        return true;
    }

    function show_country_select_list(location_type, item, index) {

        console.log(location_type);

        var to_loop ;
        location_quota_options.forEach(function(value, this_index) {

            if(value.key==location_type && value.options){

                to_loop = [...value.options];

            }


        });

        console.log("got_here");
        var return_country_select_opt = function (item,index){

            if(item.data_val){
                return         <option key={index} value={item.data_val}>{item.data_val}</option>;

            }
            if(location_type=='regions')
                return         <option key={index} value={item.Region}>{item.Region}</option>;


            if(location_type=='divisions')
                return         <option key={index} value={item.Division}>{item.Division}</option>;




        }

        console.log(location_type);

        var location_error_element =function(item) {

            return item.location=="" ? "Please select a Value" : "the same value cannot be entered twice, please fix."

        }



        if(location_type=='regions' || location_type=='divisions'){

         console.log(item);
            return <div>
            <select
                {...register('location_val'+index, {validate:function(value){

                        if(!validate_location_val_at_index(index,item.location_val)) return 'no_location_provided';}

                    })}

                className="select_field" data-name="location_val" data-type="location" data-index={index} onChange={handleAgeGenderChange} name={"quotas["+item.id+"][location_val]"} value={item.location_val} id={"quota_locationval"+index}>
                {to_loop.map((item,index) => (


                    return_country_select_opt(item,index)

                ))
                }
            </select>
                {errors["location_val"+index] && <span
                    className="error_span">{location_error_element(item)}</span>}


        </div>;
        }

        else
        if(location_type=='constituencies' || location_type=='districts'){
            return <div>

                <Select

                    {...register('location_val'+index, {validate:function(value){
                            if(!validate_location_val_at_index(index,item.location_val)) return 'no_location_provided';
                        }})}

                    getOptionLabel={option => option.data_val}
                          getOptionValue={option => option.data_val}
                          options={to_loop}
                          defaultValue={ {data_val:item.location_val ? item.location_val : ''}}
        className="select_field"
                          data-name="location_val" data-type="location" data-index={index} onChange={handleLocselectchange} name={"quotas["+item.id+"][location_val]"} id={"quota_locationval"+index}
                />
                {errors["location_val"+index] && <span
                    className="error_span">{location_error_element(item)}</span>}

            </div>


        }
        else
        if(location_type=='states' || location_type=='dmas' || location_type=='countys'){

            console.log(item);
            console.log(to_loop);


                return <div className="location_delete_hack">

            <Select
                className="select_field"
                {...register('location_val'+index, {validate:function(value){

                        if(!validate_location_val_at_index(index,item.location_val)) return 'no_location_provided';

                    }})}
                getOptionLabel={option => option.option_val}
                      getOptionValue={option => option.option_val}
                      options={to_loop}
                      defaultValue={ {option_val:item.location_val ? item.location_val : ''}}

                      data-name="location_val" data-type="location" data-index={index} onChange={handleLocselectchange} name={"quotas["+item.id+"][location_val]"}  id={location_type+"_quota_locationval"+index}

            />

                    {errors["location_val"+index] && <span
                        className="error_span">{location_error_element(item)}</span>}

                </div>


        }

    }

    function set_quotas_by_type(type,data){
        if(type=='age')
            setAgeQuotas(data);

        if(type=='gender')
            setGenderQuotas(data);
        if(type=='location') {
            setLocationQuotas(data);
        }
    }

    async function get_nat_rep_in(type){

        if(auto_populate_data[type]=='is_submiting'){
            return;
        }

        if(auto_populate_data[type]) {


            set_quotas_by_type(type,auto_populate_data[type]);

            if(type=='location'){
                setLocType('regions');
            }


            return;
        }

        try {

            auto_populate_data[type]= 'is_submitting';
            const response =  await fetch(window.api_url+'adtest/main/get_auto_populate_by_type?sid='+project_id+'&type='+type+'&'+scripts.get_api_url_suffix()); // Replace with your API endpoint

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();

                var new_auto_populate =get_empty_auto_populate_cache();

                new_auto_populate[type] = data;
                setAutoPopulateData(new_auto_populate);



            if(type=='age')
                setAgeQuotas(data);

            if(type=='gender')
                setGenderQuotas(data);
            if(type=='location') {
                setLocType('regions');
                setLocationQuotas(data);
            }



        } catch (error) {
            console.log(error);
        }
        console.log("do_nat");


    }
    function get_national_rep(type) {

    get_nat_rep_in(type);

    }


    function is_targeting_in_db(type,item){
        var found = false;


        survey_targeting_options[type].forEach(function(value, this_index){
            if(value==item){
                found = true;
            }

        });


        return found;
    }
    function show_additional_targeting_section(type) {
        return <div className={"additional_targeting_section targeting_section_"+type}>

            {additional_targeting_options[type].map((item,index) => (

                <div key={type+index} className="tick one-third">
                    <input defaultChecked={is_targeting_in_db(type,item)} value={item} type="checkbox" id={"additional_targeting_"+type+"_"+index} name={"additional_targeting_"+type+"[]"}/>
                    <label htmlFor={"additional_targeting_"+type+"_"+index}>{item}</label>
                </div>

            ))
            }
        </div>
    }

    function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
    }



    function check_overlap_two_age_ranges(count_one,count_two){


        var first_row = $('.quota_div_wrapper').eq(count_one);


        var first_row_from_val = parseInt(first_row.find('.age-from').val());
        var first_row_to_val = parseInt(first_row.find('.age-to').val());



        if(first_row_from_val>=first_row_to_val){

            return true;

        }

        var second_row = $('.quota_div_wrapper').eq(count_two);

        var second_row_from_val = parseInt(second_row.find('.age-from').val());
        var second_row_to_val = parseInt(second_row.find('.age-to').val());



        console.log("overlap for  :"+first_row_from_val+" to : "+first_row_to_val);

        console.log("two lap  :"+second_row_from_val+" to : "+second_row_to_val);



        for(var i=first_row_from_val;i<=first_row_to_val;i++){
            if(i>=second_row_from_val && i<=second_row_from_val ){
                console.log("range : "+count_one+" and range : "+count_two+" overlap");
                return true;
            }
        }


        return false;
    }


    return (
        <div>

            <div className="allpagewrapper">
                <div className="leftbar">
                    <Logo />

                </div>

                <div className="pagecontents">
                    <h1>{project_title ? "Targeting - "+project_title : ""}</h1>

                    {project_title ?
                    <h4 className="page_sub_title">
                        <span className="">&nbsp;(Step 2 of 3)</span>
                    </h4> : ""}
                    <div className="page_contents_wrapper">


                        {loading ? (
                            <p>Loading...</p>
                        ) : error ? (
            <ConnectionError />
                        ) : (

                            <div className="form EditProjectfrm" onSubmit={handleSubmit(onSubmit)}>
                                <form action="">



<h4  className="quota_row_heading">
    <span onClick={() => {get_national_rep('age')}} className="nat_rep" id="nat_rep_age">

      <Popup className="editproject"
          on={['hover', 'focus']}
          trigger={open => (
        <PeopleAduienceIco />

          )}
          position="left center"
          closeOnDocumentClick
      >
    <span>Set Age National Representative Quotas</span>
  </Popup>

    </span>
    <span><span className="calcicowrap"><CalendarIco /></span> Age</span></h4>
                                    {age_quotas.map((item,index) => (
                                    <div className="quota_div_wrapper age_quota_div_wrapper" key={"agequota"+index}>

                                        <input type="hidden" name={"quotas["+item.id+"][type]"} value="age" />


                                        <div className="quota_age_row quota_row">
                                            <div className="col-6 first">



                                                <div className="label_wrapper col-6">
                                                    <label htmlFor={"quota_age_from"+index}>Age from*</label>

                                                        <input className="age-from"  min="13" max="99" {...register("quota_age_from"+index, { required: true})} data-name="age_from" data-type="age" data-index={index} onChange={handleAgeGenderChange} id={"quota_age_from"+index} type="number" name={"quotas["+item.id+"][age_from]"} value={item.age_from} />

                                                    {errors["quota_age_from"+index] && <div
                                                        className="error_span">Please Enter a valid age from</div>}



                                                </div>


                                                <div className="label_wrapper col-6">
                                                    <label htmlFor={"quota_age_to"+index}>Age to*</label>
                                                    <div>

                                                        <input className="age-to" min="13" max="99" {...register("quota_age_to"+index, { required: true})} data-name="age_to" data-type="age" data-index={index} onChange={handleAgeGenderChange} id={"quota_age_to"+index} type="number" name={"quotas["+item.id+"][age_to]"} value={item.age_to} />

                                                    </div>

                                                    {errors["quota_age_to"+index] && <div
                                                        className="error_span">Please Enter a valid age To</div>}

                                                </div>

                                            </div>
                                            <div className="col-6">


                                                {right_quota(item,"age",index)}


                                            </div>
                                        </div>
                                    </div>

                                    ))
                                    }

<div className="clear"></div>
                                    <input
                                        {...register("ages_validate", {validate:function(value){


                                                var has_errors = false;
                                                var num_age_rows = age_quotas.length;
                                                for(var i=0;i<num_age_rows;i++){



                                                    for(var j=0;j<num_age_rows;j++) {

                                                        if(i!=j){
                                                            var overlap_found = check_overlap_two_age_ranges(i,j);
                                                            if(overlap_found){
                                                                has_errors = true;
                                                            }
                                                        }

                                                    }



                                                }




                                                if(has_errors){
                                                    return 'error';
                                                } else {



                                                    var found_offending = false;
                                                    $('.quota_div_wrapper').each(function(){

                                                        var age_from_val = parseInt($(this).find('.age-from').val());
                                                        var age_to_val = parseInt($(this).find('.age-to').val());

                                                        if(age_from_val>age_to_val){
                                                            found_offending = true;
                                                        }

                                                    });



                                                    if(found_offending){
                                                        return 'error';
                                                    }

                                                }


                                        }})}

                                        type="hidden" name="ages_validate" />
                                        {errors.ages_validate && <div className="error_span ages_validate_error">Ages cannot overlap.</div>}

                                    <div className="clear"></div>
                                    <h4  className="quota_row_heading">

                                        <span onClick={() => {get_national_rep('gender')}} className="nat_rep" id="nat_rep_age">

                                                  <Popup className="editproject"
                                                         on={['hover', 'focus']}
                                                         trigger={open => (
                                                             <PeopleAduienceIco />

                                                         )}
                                                         position="left center"
                                                         closeOnDocumentClick
                                                  >
    <span>Set Gender National Representative Quotas</span>
  </Popup>

                                        </span>

                                        <span><Genderamb /> Gender</span></h4>
                                    {gender_quotas.map((item,index) => (
                                        <div className="quota_div_wrapper gender_quota_div_wrapper" key={"genderquota"+index}>

                                            <div className="quota_gender_row quota_row">
                                                <div className="col-6 first">

                                                    <input type="hidden" name={"quotas["+item.id+"][type]"} value="gender" />

                                                    <div className="label_wrapper col-6">

                                                        <label htmlFor={"quota_gender"+index}>Gender</label>
                                                        <div>

                                                            <select    {...register("quota_gender"+index, {validate:function(value){



                                                                var myid = 'quota_gender'+index;

                                                                var select_value = $('#'+myid).val();

                                                                var was_found = false;


                                                                $('.gender_quota_field').not('#'+myid).each(function(){

                                                                    if($(this).val()==select_value){
                                                                        was_found = true;
                                                                    }


                                                                });


        if(was_found)
            return 'error';


                                                                }})}
                                                                       className="gender_quota_field"
                                                                       data-name="gender" data-type="gender" data-index={index} onChange={handleAgeGenderChange} name={"quotas["+item.id+"][gender]"} value={item.gender} id={"quota_gender"+index}>
                                                                <option value="m">Male</option>
                                                                <option value="f">Female</option>
                                                                <option value="nb">Nonbinary</option>
                                                            </select>



                                                        </div>

                                                    </div>

                                                    <div className="clear"></div>
                                                    {errors["quota_gender"+index] && <div
                                                        className="error_span genders_error_span">Genders cannot overlap</div>}



                                                </div>
                                                <div className="col-6">



                                                    {right_quota(item,"gender",index)}


                                                </div>
                                            </div>
                                        </div>

                                    ))
                                    }


                                    <div className="clear"></div>
                                    <h4 className="quota_row_heading">
                                        {location_type=='regions' ?
                                        <span onClick={() => {get_national_rep('location')}} className="nat_rep" id="nat_rep_age">
                                                  <Popup className="editproject"
                                                         on={['hover', 'focus']}
                                                         trigger={open => (
                                                             <PeopleAduienceIco />

                                                         )}
                                                         position="left center"
                                                         closeOnDocumentClick
                                                  >
    <span>Set Location National Representative Quotas</span>
  </Popup>

                                        </span> : <div></div>}

                                        <span><LocationIco /> Location</span>
                                        <select onChange={set_location_type} value={location_type} name="location_type" id="location_type">
                                            <option value="national">National</option>
                                            {location_quota_options.map((item) => (
                                                <option key={item.key} value={item.key}>{item.name=='DMA_NAME' ? 'DMA' : item.name}</option>
                                            ))
                                            }
                                        </select>
                                    </h4>










                                    {location_quotas.map((item,index) => (
                                        <div className="quota_div_wrapper" key={"locationquota"+index}>

                                            <div className="quota_age_row quota_row">
                                                <div className="col-6">

                                                    <input type="hidden" name={"quotas["+item.id+"][type]"} value="location" />


                                                    <div className="label_wrapper col-6">


                                                        <label>{location_type_label}</label>

                                                        {show_country_select_list(location_type,item,index)}




                                                        <div>


                                                        </div>

                                                    </div>



                                                </div>
                                                <div className="col-6">



                                                    {right_quota(item,"location",index)}



                                                </div>
                                            </div>
                                        </div>

                                    ))
                                    }
                                <div className="clear"></div>


                                    {data.adtest_product_id=="3" ?
                                    <div className="additional_targeting_all">
                                    <div className="custom_quotas">
                                        <h4 className="quota_row_heading"><span className="targeticowrap"><TargetIco /></span> Additional Targeting <input
                                            type="checkbox" name="additional_targeting" value={show_additional_targeting}
                                            onChange={(e) => {


                                                console.log(e.target.checked);
                                                setShowAdditional(e.target.checked);
                                            }}

                                        /></h4>
                                    </div>




                                    <div className={"additional_targeting_wrapper"+(show_additional_targeting ? " show": "")}>


                                    <span className="headsa"><span className="add_ico_wrap"><PersonIco /></span> Marital Status</span>
                                    {show_additional_targeting_section('marital')}

                                    <div className="clear"></div>
                                    <span className="headsa"><span className="add_ico_wrap"><WorkIco /></span> Employment</span>
                                    {show_additional_targeting_section('employment')}

                                    <div className="clear"></div>

                                    <span className="headsa"><span className="add_ico_wrap"><PeopleIco /></span> Ethnicity</span>
                                    {show_additional_targeting_section('ethnicity')}



                                    <div className="clear"></div>


                                    <div className="headsa children"><span className="add_ico_wrap"><ChildIco /></span> Children</div>

                                    {children_options.map((item) => (
                                        <div key={item} className={"one-quarter "+item}>
                                            <div className="label_wrapper">
                                                <label htmlFor={item}>{capitalizeFirstLetter(item.replaceAll("_"," "))}
                                                </label>
                                                <div>
                                                    <input type="number" id={item} name={item} defaultValue={additional_children_data[item]} />
                                                </div>

                                            </div>

                                        </div>
                                    ))
                                    }
                                    </div>

                                    </div>

                                        : ''}


<div className="clear"></div>


                                    <div className="calculator_more_spacing">

                                    <Calculator product_price={adtest_amount_left_to_pay} display="true" />
                                    </div>

                                    <div className="submitor">
                                        {is_submitting ?
                                            <PurpleLoader height={40} /> : ''}

                                        <input disabled={is_submitting} type="submit" value="Continue"/>
                                    </div>

                                    <div id="more_spacing"></div>

                                </form>
                                {/*<DevTool control={control} /> /!* set up the dev tool *!/*/}

                                <div className="clear"></div>
                            </div>



                        )}



                    </div>
                </div>
            </div>

        </div>
    );
};
export default  EditProject
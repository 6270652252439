import LogoAnimated from '../LOGO_S1_WHITE_quick_infinite_animation.svg';
import React from "react";
import Logo from "./Logo";
class PurpleLoader extends React.Component {
    render() {

        return <object className="animated_loader" height="45" type="image/svg+xml" data={LogoAnimated}>adtest-circle</object>

    }

}

export default PurpleLoader;
//Home.js
import React, {useEffect, useState} from 'react'
import $ from 'jquery';
import Navbar from './Navbar'
import { Link } from "react-router-dom";
import './App.css';
import './Scripts.js';
import * as scripts from "./Scripts";
import Logo from './components/Logo'

import { useNavigate } from 'react-router-dom';
import PurpleLoader from './components/PurpleLoader';
import ConnectionError from "./components/ConnectionError";

const Wallet = () => {
    const navigate = useNavigate();
    scripts.on_before_load_redirect_inside(navigate);

    const [data, setData] = useState(0);


    const [loading, setLoading] = useState(true);

    const [error, setError] = useState(null);
    const fetchData = async () => {

        try {
            const response = await fetch(window.api_url+'adtest/main/adtest_get_wallet_data?'+scripts.get_api_url_suffix()); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();

            setData(data);

            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }

    }

    const [is_submitting, SetSubmitting] = useState(false);
    useEffect(() => {
        fetchData();
        scripts.fix_page_contents_class_height();

    }, [])


    return (
        <div>

            <div className="allpagewrapper">
                <div className="leftbar">
                    <Logo />


                </div>

                <div className="pagecontents" id="wallet_page_contents">
                    <div className="credits_headline">
                        Available Balance: {data.total_credits}
                    </div>



                    <h1>Wallet</h1>


                    {loading ? (
                        <p>Loading...</p>
                    ) : error ? (
                        <ConnectionError />
                    ) :
<div>
                        <table id="tablewallet">
                            <thead>
                            <tr>
                                <td>Date</td>
                                <td>Type</td>
                                <td>Project Title</td>
                                <td>Amount</td>
                            </tr>
                            </thead>
                            <tbody>
                            {data.walletdata?.map((item,index) => (
                                <tr className={"walelt_row wallet_row_"+item.credit_type}>
                                    <td>{item.created_at}</td>
                                    <td>{item.credit_type}</td>
                                    <td><Link className="underln" to={"/confirmation/"+item.survey_id}>{item.survey_title}</Link></td>
                                    <td className="amount">{item.amount}</td>
                                    
                                </tr>


                            ))
                            }

                            </tbody>
                        </table>

<div id="more_spacing"></div>
</div>
                    }

                </div>
            </div>
        </div>

    )
}

export default Wallet